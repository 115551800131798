import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import MDButton from 'components/MDButton';
import TextField from '@mui/material/TextField';
import BootstrapInput from 'components/MDField';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { CheckCircleOutline, GradingRounded, KeyboardReturnRounded, MoreVertOutlined, RemoveCircleOutline } from '@mui/icons-material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import { func } from 'prop-types';
import {Dialog,DialogContent,DialogTitle,DialogContentText,DialogActions} from "@mui/material";


var drawerWidth = 350;

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


function Transitotranf(props) {
    
    const [checked, setChecked] = useState([]);
    const [lotes, setLotes] = useState([])
    const [lotesReal, setLotesReal] = useState([])
    const [currentLote, setCurrentLote] = useState('')
    const [currentLoteData, setCurrentLoteData] = useState({})
    const [recibidos, setRecibidos] = useState([]);
    const [transferidos, setTransferidos] = useState([]);
    const [devoluciones, setDevoluciones] = useState([]);
    const [tituloMsj,setTituloMsj] = useState("")
    const [descripMsj,setDescripMsj] = useState("")
    const [currentValueRecib, setCurrentValueRecib] = useState({})
    const [comentario,setComentario] = useState('Comentario')
    const [transportistaDevolucion,setTransportistaDevolucion] = useState('Transportista')
    const [comentarioDevolucion,setComentarioDevolucion] = useState('Comentario')
    const recibidosChecked = intersection(checked, recibidos);
    const transferidosChecked = intersection(checked, transferidos);
    const [abrirmensaje,setAbrirmensaje] = useState(false)
    const [complete,setComplete] = useState(false)

    useEffect(() => {
      const request = {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"rol":localStorage.getItem("roleweb"),"sucursal":localStorage.getItem("sucursal"),'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
      }
      fetch(process.env.REACT_APP_SERVIDOR+'/api/getlotetransf',request)
      .then(function(response){
        if (response.status === 200) {
            response.json().then(function(data){

               var result = data["motor"].map((value,index)=> index+". "+value["stransmisor"].split("-")[1]+" - "+value["sreceptor"].split("-")[1] + " "+value["fechatransm"])

               setLotes(result)
               setLotesReal(data["motor"])  
              
            })
            
        }
        if (response.status === 400) {
            response.json().then(function(data){
              ///setRows([]) 
//              alert(data["Error"])
              
            })
        }})
    },[]);

    
    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };
  
    const handleAllTransferidos = () => {
      setTransferidos(transferidos.concat(recibidos));
      setRecibidos([]);
    };
  
    const handleCheckedTransferidos = () => {
      setTransferidos(transferidos.concat(recibidosChecked));
      setRecibidos(not(recibidos, recibidosChecked));
      setChecked(not(checked, recibidosChecked));
    };
  
    const handleCheckedRecibidos = () => {
      setRecibidos(recibidos.concat(transferidosChecked));
      setTransferidos(not(transferidos, transferidosChecked));
      setChecked(not(checked, transferidosChecked));
    };
  
    const handleAllRecibidos = () => {
      setRecibidos(recibidos.concat(transferidos));
      setTransferidos([]);
    };
    
    const customList = (items) => (
      <Paper sx={{ width: 300, height: '70vh', overflow: 'auto' }}>
        <List dense component="div" role="list">
          {
          items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;
            
  
            return (
              <ListItemButton
                key={value}
                role="listitem"
              >
                <ListItemIcon>
                  <Checkbox
                    onClick={handleToggle(value)}
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.chasis} />
                {items==recibidos?
                  value.devoluciones==undefined?
                    <MoreVertOutlined onClick={(e)=>{handleClick(e,value)}}/>
                  :
                  !value.devoluciones.includes("Devolucion")?
                  <MoreVertOutlined onClick={(e)=>{handleClick(e,value)}}/>
                  :''
                :''}


                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',}}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}>
                
                  <MenuItem onClick={()=>{addDevoluciones(currentValueRecib)}} disableRipple>
                    <KeyboardReturnRounded/>
                    Devolver
                  </MenuItem>
                </StyledMenu>

              </ListItemButton>
            );
          })}
        </List>
      </Paper>
    );

  

    function buscarmotores(){

      var value = lotesReal[Number(currentLote.split(".")[0])]

      const request = {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({'idtransaccion': value["idtransaccion"],'password':localStorage.getItem("password"),usuario:localStorage.getItem('usuario')}) 
      }
      fetch(process.env.REACT_APP_SERVIDOR+'/api/getLotespecifico',request)
      .then(function(response){
        if (response.status === 200) {
            response.json().then(function(data){
              //rows = [data["facturacion"]]
               var value = {idtransaccion:data["motor"][0]["idtransaccion"],
                            stransmisor:data["motor"][0]["stransmisor"].split("-")[1],
                            sreceptor:data["motor"][0]["sreceptor"].split("-")[1],
                            stransmisornum:data["motor"][0]["stransmisor"].split("-")[0],
                            sreceptornum:data["motor"][0]["sreceptor"].split("-")[0],                            
                            fechatransm:data["motor"][0]["fechatransm"],
                            comentransm:data["motor"][0]["comentransm"]
               }
               setRecibidos(data["motor"])
               setCurrentLoteData(value)
               
            })
            
        }
        if (response.status === 400) {
            response.json().then(function(data){
              //setRows([]) 
              alert(data["Error"])
              
            })
        }
      })
    
    
    }
    

    function addDevoluciones(row){
    //  alert(row.Chasis)
      const newItems = recibidos.filter(item => item !== row);
      setRecibidos(newItems);

      setDevoluciones([...devoluciones,row])    
      setAnchorEl(null)
    }

    function removeDevoluciones(row){
      const newItems = devoluciones.filter(item => item !== row);
      setDevoluciones(newItems);
    
      setRecibidos([...recibidos,row])
    
    }
    
    function recibirTransferencia(){
      setAbrirmensaje(true)
      setTituloMsj("Recibir Transferencia")
    }

    function devolver(){
      setAbrirmensaje(true)
      setTituloMsj("Devolver Matriculas")

    }

    function confirmDevoler(){
      const request = {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({'sucursal': currentLoteData["sreceptornum"]+"-"+currentLoteData["sreceptor"],"sucursalrecep":currentLoteData["stransmisornum"]+"-"+currentLoteData["stransmisor"]
          ,"transacprincipal":currentLoteData["idtransaccion"],"motores":devoluciones,"comentario":comentarioDevolucion=="comentario"?'':comentarioDevolucion,
          'password':localStorage.getItem("password"),usuario:localStorage.getItem('usuario')}) 
      }
      fetch(process.env.REACT_APP_SERVIDOR+'/api/devoluciontransferencia',request)
      .then(function(response){
        if (response.status === 200) {
          setDevoluciones([])        
          setAbrirmensaje(false)

          if(transferidos.length==0 && recibidos.length==0){
            setComplete(true)
          }
        }
        if (response.status === 400) {
            response.json().then(function(data){
  //            setRows([]) 
    //          alert(data["Error"])
              
            })
        }
      })

    }

    function confirm(){



      const request = {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({'sucursal': Number(currentLoteData["sreceptornum"]),"sucursaltrans":Number(currentLoteData["stransmisornum"])
          ,"motores":transferidos,"comentario":comentario=="comentario"?comentario:'',
          'password':localStorage.getItem("password"),usuario:localStorage.getItem('usuario')}) 
      }
      fetch(process.env.REACT_APP_SERVIDOR+'/api/recibirtransferencia',request)
      .then(function(response){
        if (response.status === 200) {
          setTransferidos([])        
          setAbrirmensaje(false)

          if(devoluciones.length==0 && recibidos.length==0){
            setComplete(true)
          }
    
        }
        if (response.status === 400) {
            response.json().then(function(data){
  //            setRows([]) 
    //          alert(data["Error"])
              
            })
        }
      })
    
    }
    
    function DialogConfirm(props) {
      
      return (
          <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx = {{
              '& .MuiDialog-paper':{borderRadius:"15px"}
            }}
          >
            <DialogTitle style={{fontWeight:"bold"}}>
              {tituloMsj}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" >
                {tituloMsj.includes("Recibir")?
                  <div>
                  Recibir transferencia desde <strong>{currentLoteData["stransmisor"]}</strong> hacia <strong>{currentLoteData["sreceptor"]}</strong>
                  </div>
                  :
                  <div>
                  Regresar transferencia desde <strong>{currentLoteData["sreceptor"]}</strong> hacia <strong>{currentLoteData["stransmisor"]}</strong>
                  </div>
              }
                  
              </DialogContentText>
            </DialogContent>
            <Box style={{display: "flex", justifyContent: "center",marginTop:"-15px"}}>
            <DialogActions>
            <MDButton variant="gradient" color="warning" style={{width:"150px",marginTop:"15px"}} onClick={()=>{setAbrirmensaje(false)}}>
                No
              </MDButton>
  
              <MDButton variant="gradient" color="success" style={{width:"150px",marginTop:"15px", fontWeight:"bold"}} onClick={()=>{tituloMsj.includes("Recibir")?confirm():confirmDevoler()}}>
                si
              </MDButton>
            </DialogActions>
            </Box>
          </Dialog>
      );
    }

    function DialogComplete(props) {
      
      return (
          <Dialog
            open={complete}
            onClose={handleCloseComplete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx = {{
              '& .MuiDialog-paper':{borderRadius:"15px"}
            }}
          >
            <DialogTitle style={{fontWeight:"bold"}}>
              Transaccion Completada
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" >
                  <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    <CheckCircleOutline style={{color:"green",height:55,width:55}}></CheckCircleOutline>
                    <br></br>
                    <p>Transaccion desde <strong>{currentLoteData["stransmisor"]}</strong> hacia <strong>{currentLoteData["sreceptor"]}</strong> exitosa</p>
                  </Box>
              
                  
              </DialogContentText>
            </DialogContent>
          </Dialog>
      );
    }


    function handleCloseComplete(){
      setComplete(false)
      window.location.reload(true)
    }
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event,value) => {
      setAnchorEl(event.currentTarget);
      
      
      setCurrentValueRecib(value)
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
  
    return (
     <Grid>
      <Grid container spacing={2} justifyContent="left" alignItems="center" marginTop={8}>
        
        <DialogConfirm open={abrirmensaje}></DialogConfirm>
        <DialogComplete></DialogComplete>

        <Grid item lg={3}>
          <h3 style={{marginTop:"-40px",marginLeft:"0px"}}>Recibir chasis...</h3>
          <Autocomplete
            style={{marginTop:"0px",backgroundColor:"white",width:"300px"}}
                    variant = "standard"    
                    disablePortal
                    id="combo-box-demo"
                    options={lotes}
                    value = {currentLote}
                    onBlur={(event) => setCurrentLote(event.target.value)}
                    sx = {{ disableUnderline: true}}
                    
                                      
                    renderInput={(params) => 
                    <TextField {...params} 
                      variant = "standard"
                      label="seleccionar lote" 
                      sx={{boxShadow: 4, borderRadius: 2,width:"300px" ,height:"55px",backgroundColor: "white"}}
                      InputLabelProps={{
                        shrink: "true",
                        style:{
                          paddingLeft: "10px",
                          paddingTop: "5px",
                          disableUnderline: true,
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,disableUnderline:true,borderRadius:5,
                        style:{
                          fontSize: "14px",
                          marginLeft: "5px",
                          marginTop: "20px",  
                        }}}
           />}
          />
          <MDButton variant="gradient" color="success" onClick={()=>buscarmotores()}  style={{width:"300px"}}>Buscar</MDButton>

          <br></br>
          <br></br>
          <br></br>

        
        </Grid>
        {currentLoteData.idtransaccion!=undefined?
        <Grid container>
          <Grid item lg={3}>
          {customList(recibidos)}

          </Grid>
        <Grid item lg={1} marginLeft={3}>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              style = {{color: "black"}}
              variant="outlined"
              size="small"
              onClick={handleAllTransferidos}
              disabled={recibidos.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              style = {{color: "black"}}
              variant="outlined"
              size="small"
              onClick={handleCheckedTransferidos}
              disabled={recibidosChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              style = {{color: "black"}}
              variant="outlined"
              size="small"
              onClick={handleCheckedRecibidos}
              disabled={transferidosChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5,color:'black' }}
              style = {{color: "black"}}
              variant="outlined"
              size="small"
              onClick={handleAllRecibidos}
              disabled={transferidos.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={3} >{customList(transferidos)}</Grid>
        <Grid item lg={4} marginLeft={4} marginTop={5}>
          
          <Box width={400} height={250} borderRadius={3} boxShadow={5} style={{backgroundColor:'white',padding:20}} >
            <Grid container>
              <Grid item lg={12} style={{backgroundColor:"white"}}>
                <h3>Detalles</h3>
              </Grid>          

              <Grid item lg={6} marginTop={2}>
                <p style={{fontWeight:"bold",fontSize:14}}>Transportador:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentLoteData["transportista"]}</p>
              </Grid>  

              <Grid item lg={6} >
                <p style={{fontWeight:"bold",fontSize:14}}>Proviene de:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentLoteData["stransmisor"]}</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontWeight:"bold",fontSize:14}}>Fecha de salida:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentLoteData["fechatransm"]}</p>
              </Grid>
              <Grid item lg={6}>
                <p style={{fontWeight:"bold",fontSize:14}}>Comentario de salida:</p>              
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentLoteData["comentransm"]}</p>
              </Grid>  
              <Grid item lg={8} marginTop={2}>
                <BootstrapInput defaultValue="Comentario" id="bootstrap-input"  fullWidth/>
              </Grid>
              <Grid item lg={4} marginTop={4} alignItems={"flex-start"}>
                {transferidos.length!=0?
                  <MDButton variant="gradient" color="success" fullWidth onClick={()=>{recibirTransferencia()}}>Recibir</MDButton>
                :''}

              </Grid>
            </Grid>

          </Box>
          

          {devoluciones.length!=0?
          <Box width={400} height={400} borderRadius={3} boxShadow={5} style={{backgroundColor:'white',marginTop:30, padding:20}} >
            <Grid container>
              <Grid item lg={12} style={{backgroundColor:"white"}}>
                <h3>Devoluciones</h3>
              </Grid>          

              <Grid item lg={12} marginTop={2}>
                <List sx={{bgcolor: 'background.paper', maxHeight:240,minHeight:240,position:'relative',overflow: 'auto',}}>
                  {devoluciones.map((value) => (
                    <ListItem>
                      <ListItemText
                        primary={value.chasis}
                        secondary={`Marca:${value.marca}, Color:${value.colore}`}
                      />
                      <RemoveCircleOutline style={{color:"red",height:30,width:30,marginTop:9}} onClick={()=>{removeDevoluciones(value)}}></RemoveCircleOutline>
                   </ListItem>
                 ))}
                </List>

              </Grid>  

              <Grid item lg={8} marginTop={2}>
              <BootstrapInput value={transportistaDevolucion} onChange={(event)=>{setTransportistaDevolucion(event.target.value)}} defaultValue="Transportista" id="bootstrap-input"  fullWidth/>
                <BootstrapInput value={comentarioDevolucion} onChange={(event)=>{setComentarioDevolucion(event.target.value)}} defaultValue="Comentario" id="bootstrap-input"  fullWidth/>
              </Grid>
              <Grid item lg={4} marginTop={8} alignItems={"flex-start"}>
                <MDButton variant="gradient" color="success" onClick={()=>{devolver()}} fullWidth>Devolver</MDButton>

              </Grid>
            </Grid>

          </Box>
          :''}

        </Grid>
        </Grid>
        :
        <Grid container>
          <Grid item lg={12}style={{backgroundColor:"white"}}  sx={{borderRadius:5,height:200,display:"flex",flexDirection:"column",justifyContent:"center" ,justifyItems:"center" ,alignItems:"center"}}>
            <GradingRounded style={{color:"#404742",height:50,width:50}}></GradingRounded>
            <p  style={{textAlign:"center",alignSelf:"center",fontWeight:"bold",fontSize:24}}>Elige una de las transferencias</p>            

          </Grid>

        </Grid>
        }
      </Grid>
     </Grid>   
    );    
}
export default Transitotranf;
